import { Controller } from "stimulus"
import { showElement, hideElement } from "controllers/utilities/display.js"

export default class extends Controller {
  static targets = [
    "momoRadio", "bankCardRadio", "alizzaRadio", "boaRadio", "paymentRadios", "paymentsOptions",
    "momoForm", "bankCardForm", "alizzaForm", "boaForm",
    "momoFormTitle", "bankCardFormTitle", "alizzaFormTitle", "boaFormTitle", "amanataFormTitle", "amanataForm",
    "feesMessage", "fees", "totalAmount", "amount",
    "amountInput", "amountErrorMessage", "amanataPhone", "amanataPhoneErrorMessage",
    "momoPhone", "momoPhoneErrorMessage", "momoPhoneCountry", "amanataPhoneCountry", "momoImages", "momoPendingMessage",
    "cardPhone", "cardPhoneErrorMessage", "cardCountry", "cardPageButton",
    "pan", "panErrorMessage", "exp", "expErrorMessage", "cvv", "cvvErrorMessage", "data3dsUrl",
    "alizzaPhone", "alizzaPhoneErrorMessage", "alizzaPhoneCountry",
    "boaPhone", "boaPhoneErrorMessage", "boaPhoneCountry",
    "alizzaPublicReference", "boaPublicReference",
    "name", "nameErrorMessage", "button",
    "paymentSuccess", "paymentFailed",
    "paymentNotification", "paymentNotificationContent", "amanataPendingStepForm",
    "step", "momoPendingStep", "amanataPendingStep", "formStep", "currentResponseStep",
    "cardPendingStep", "alizzaPendingStep", "boaPendingStep", "3dsFrameStep", "card3dsStep",
    "data3dsForm", "data3dsPaReq", "data3dsMd"
  ]

  static values = {
    objectId: String,
    objectClass: String,
    publicKey: String,
    maxAmount: Number,
    momoFees: Number,
    bankCardFees: Number,
    amanataFees: Number,
    boaFees: Number,
    alizzaFees: Number,
    paymentReference: String,
    fixedAmount: Boolean,
    hasIframeParent: Boolean,
    parentDomaine: String,
    transactionId: String
  }

  connect () {
    this.MTN_BJ_MSISDN = /^229(97|96|91|90|69|67|66|62|61|54|53|52|51|42)/
    this.MOOV_BJ_MSISDN = /^229(99|98|95|94|65|64|55)/
    this.MOOV_NE_MSISDN = /^227(74|75|84|85|94|95)/
    this.AIRTEL_NE_MSISDN = /^227(96|97|98|99|87|88|89|86|76|77)/
    this.ZAMANI_NE_MSISDN = /^227(70|80|81|90|91|92)/
    this.selectedProvider = null;
    this.paymentMethod = null
    this.amount = this.fixedAmountValue ? this.maxAmountValue : 0
    this.fees = this.momoFeesValue
    this.totalAmount = 0
    this.name = this.hasNameTarget ? this.nameTarget.value : ''
    if (this.hasMomoFormTarget) this.momoPhone = this.momoPhoneTarget.value
    if (this.hasBankCardFormTarget) {
      this.cardPhone = this.cardPhoneTarget.value
      this.countryCode = 'NE'
      this.cardCountry = '227'
      this.pan = this.panTarget.value
      this.exp = this.expTarget.value
      this.cvv = this.cvvTarget.value

      this.panInputOldValue
      this.panInputOldCursor
      this.isValidatedPan = false
      this.expInputOldValue
      this.isValidatedExp = false
      this.cvvInputOldValue
      this.isValidatedCvv = false
    }
    if (this.hasAlizzaFormTarget) this.alizzaPhone = this.alizzaPhoneTarget.value
    if (this.hasBoaFormTarget) this.boaPhone = this.boaPhoneTarget.value
    if (this.hasAmanataFormTarget) this.amanataPhone = this.amanataPhoneTarget.value
    this.paymentReference = ''
    this.bankCardPageUrl = ''

    this.switchPaymentMethod()
    this.calculateAmount()
    if (this.hasFeesMessageTarget) this.updateAmountDatainUI()

    document.addEventListener("payment:succeed", this.updatePayment.bind(this));
  }

  displayForm(e) {
    this.changePaymentMethod(e)
    hideElement(this.paymentsOptionsTarget)
    showElement(this.formStepTarget)
  }

  backToOptions() {
    showElement(this.paymentsOptionsTarget)
    hideElement(this.formStepTarget)    
  }
  switchPaymentMethod() {
    if (this.hasMomoFormTarget) hideElement(this.momoFormTarget)
    if (this.hasBankCardFormTarget) hideElement(this.bankCardFormTarget)
    if (this.hasAlizzaFormTarget) hideElement(this.alizzaFormTarget)
    if (this.hasBoaFormTarget) hideElement(this.boaFormTarget)
    if (this.hasAmanataFormTarget) hideElement(this.amanataFormTarget)

    if (this.hasMomoFormTitleTarget) hideElement(this.momoFormTitleTarget)
    if (this.hasBankCardFormTitleTarget) hideElement(this.bankCardFormTitleTarget)
    if (this.hasAlizzaFormTitleTarget) hideElement(this.alizzaFormTitleTarget)
    if (this.hasBoaFormTitleTarget) hideElement(this.boaFormTitleTarget)
    if (this.hasAmanataFormTitleTarget) hideElement(this.amanataFormTitleTarget)
      
    switch (this.paymentMethod) {
      case 'momo':
        showElement(this.momoFormTarget)
        showElement(this.momoFormTitleTarget)
        break;
      case 'bankCard':
        showElement(this.bankCardFormTarget)
        showElement(this.bankCardFormTitleTarget)
        break;
      case 'alizza':
        showElement(this.alizzaFormTarget)
        showElement(this.alizzaFormTitleTarget)
        break;
      case 'boa':
        showElement(this.boaFormTarget)
        showElement(this.boaFormTitleTarget)
        break;
      case 'amanata':
        showElement(this.amanataFormTarget)
        showElement(this.amanataFormTitleTarget)
        break;
    }
  }

  calculateAmount() {
    switch (this.paymentMethod) {
      case 'momo':
        this.fees = this.momoFeesValue
        break;
      case 'bankCard':
        this.fees = this.bankCardFeesValue
        break;
      case 'alizza':
        this.fees = this.alizzaFeesValue
        break;
      case 'boa':
        this.fees = this.boaFeesValue
        break;
      case 'amanata':
        this.fees = this.amanataFeesValue
        break;
    }

    if (this.fees == 0) {
      this.totalAmount = this.amount
    }
    else {
      this.totalAmount = Math.ceil(this.amount + (this.amount*this.fees)/100)
    }
  }

  updateAmountDatainUI() {
    if (this.fees == 0) {
      hideElement(this.feesMessageTarget)
    } else {
      showElement(this.feesMessageTarget)
    }

    this.feesTarget.textContent = this.fees
    this.totalAmountTarget.textContent = this.totalAmount
    // this.amountTarget.textContent = this.amount
  }

  amountChanged(e) {
    hideElement(this.amountErrorMessageTarget)

    this.amount = parseInt(this.amountInputTarget.value) || 0

    if (this.amount <= 0 || (this.maxAmountValue > 0 && this.amount > this.maxAmountValue)) {
      showElement(this.amountErrorMessageTarget)
    }

    this.calculateAmount()
    this.updateAmountDatainUI()
  }

  nameChanged(e) {
    hideElement(this.nameErrorMessageTarget)

    this.name = this.nameTarget.value.trim()

    if (this.name == '') {
      showElement(this.nameErrorMessageTarget)
    }
  }

  momoPhoneChanged() {
    hideElement(this.momoPhoneErrorMessageTarget)
    this.momoImagesTargets.forEach(img => {
      img.classList.add("blanc")
      img.classList.remove("selected")
    })

    this.momoPhone = this.momoPhoneTarget.value.trim()
    var msisdnCode = this.momoPhoneCountryTarget.value == 'NE' ? '227' : '229';
    var momoPhone = msisdnCode + this.momoPhone;

    if (this.momoPhone === '') {
      showElement(this.momoPhoneErrorMessageTarget)
    } else {
      this._updateMomoImages(momoPhone)
    }
  }

  amanataPhoneChanged(e) {
    hideElement(this.amanataPhoneErrorMessageTarget)

    this.amanataPhone = this.amanataPhoneTarget.value.trim()

    if (this.amanataPhone == '') {
      showElement(this.amanataPhoneErrorMessageTarget)
    }

  }

  staPhoneChanged(e) {
    let type = e.target.dataset.type
    hideElement(this[`${type}PhoneErrorMessageTarget`])
    this[`${type}Phone`] = this[`${type}PhoneTarget`].value.trim()

    if (this[`${type}Phone`] == '') {
      showElement(this[`${type}PhoneErrorMessageTarget`])
    }
  }

  cardPhoneChanged(e) {
    hideElement(this.cardPhoneErrorMessageTarget)

    this.cardPhone = this.cardPhoneTarget.value.trim()

    if (this.cardPhone == '') {
      showElement(this.cardPhoneErrorMessageTarget)
    }
  }

  cardCountryChanged(e) {
    const countryCodeArray = this.cardCountryTarget.value.split(' '); // split at space
    this.cardCountry = countryCodeArray[0];
    this.countryCode = countryCodeArray[1];
  }

  panChanged(e) {
    this._validePan()
    let panPattern = /^\d{0,16}$/g,
      separator = " ",
      unMarkedPan = this._unmask(this.panTarget.value),
      newCursorPosition

    if (unMarkedPan.match(panPattern)) {
      unMarkedPan = this._mask(unMarkedPan, 4, separator);

      newCursorPosition =
        this.panInputOldCursor - this._checkSeparator(this.panInputOldCursor, 4) +
        this._checkSeparator(this.panInputOldCursor + (unMarkedPan.length - this.panInputOldValue.length), 4) +
        (this._unmask(unMarkedPan).length - this._unmask(this.panInputOldValue).length);

      this.panTarget.value = (unMarkedPan !== "") ? unMarkedPan : "";
      this.pan = this.panTarget.value
      this.isValidatedPan = true
      this._validePan()
    } else {
      this.isValidatedPan = false
      this._validePan()
      this.panTarget.value = this.panInputOldValue;
      newCursorPosition = this.panInputOldCursor;
    }

    this.panTarget.setSelectionRange(newCursorPosition, newCursorPosition)
  }

  expChanged(e) {
    this._valideExp()
		let expPattern = /^\d{0,4}$/g,
        expSeparator = "/",
        unMarkedExp = this._unmask(this.expTarget.value)

    if (unMarkedExp.match(expPattern)) {
      unMarkedExp = this._mask(unMarkedExp, 2, expSeparator);
      this.expTarget.value = unMarkedExp;
      this.exp = this.expTarget.value
      this.isValidatedExp = true
      this._valideExp()
    } else {
      this.expTarget.value = this.expInputOldValue;
      this.isValidatedExp = false
      this._valideExp()
    }
  }

  cvvChanged(e) {
    this._valideCvv()
		let cvvPattern = /^\d{0,3}$/g,
        unMarkedCvv = this._unmask(this.cvvTarget.value)

    if (unMarkedCvv.match(cvvPattern)) {
      this.cvv = this.cvvTarget.value
      this.isValidatedCvv = true
      this._valideCvv()
    } else {
      this.cvvTarget.value = this.cvvInputOldValue;
      this.isValidatedCvv = false
      this._valideCvv()
    }
  }

  panInputKeyDownHandler(e) {
    let el = e.target;
    this.panInputOldValue = el.value;
    this.panInputOldCursor = el.selectionEnd;
  }

  expInputKeyDownHandler (e) {
    let el = e.target;
    this.expInputOldValue = el.value;
  }

  cvvInputKeyDownHandler (e) {
    let el = e.target;
    this.cvvInputOldValue = el.value;
  }

  changePaymentMethod(e) {
    this.paymentMethod = e.target.value
    this.switchPaymentMethod()
    this.calculateAmount()
    this.updateAmountDatainUI()
  }

  resquestPayment(e) {
    hideElement(this.nameErrorMessageTarget)
    hideElement(this.amountErrorMessageTarget)

    if (this.name == '') {
      showElement(this.nameErrorMessageTarget)
    }

    if (this.amount == 0) {
      showElement(this.amountErrorMessageTarget)
    }

    if (this.name == '' || this.amount == 0) {
      return
    }

    switch (this.paymentMethod) {
      case 'momo':
        this._requestMomoPayment();
        break;
      case 'bankCard':
        this._requestBankCardPayment();
        break;
      case 'alizza':
        this._requestStaPayment('alizza');
        break;
      case 'boa':
        this._requestStaPayment('boa');
        break;
      case 'amanata':
        this._requestAmanataPayment();
        break;
    }
  }

  updatePayment(e) {
    if (this.paymentReference != e.detail.reference) { return; }

    const status = e.detail.status;

    if (this.hasIframeParentValue) {
      var data = e.detail.otherData
      parent.postMessage({type: "payment.response", other: data}, this.parentDomaineValue);
    }

    if (status == 'succeeded') {
      this.paymentSuccessTarget.setAttribute("id", `payment_success_${e.detail.reference}`)
      this._displayStep("currentResponseStep");
    }

    if (status == 'failed') {
      this.paymentFailedTarget.setAttribute("id", `payment_failed_${e.detail.reference}`)
      this._displayStep("currentResponseStep");
    }
  }

  openPaymentPage() {
    var url = this.cardPageButtonTarget.dataset.url
    window.open(`${url}/orabank_payment_page/?payment_reference=${this.paymentReference}&page_url=${this.bankCardPageUrl}`, '_blank')
    this._displayStep("cardPendingStep");
  }

  closeServerMessage() {
    hideElement(this.paymentNotificationTarget)
  }

  _validateMSISDN(momoPhone) {
    return (
      this.MTN_BJ_MSISDN.test(momoPhone) ||
      this.MOOV_BJ_MSISDN.test(momoPhone) ||
      this.MOOV_NE_MSISDN.test(momoPhone) ||
      this.AIRTEL_NE_MSISDN.test(momoPhone) ||
      this.ZAMANI_NE_MSISDN.test(momoPhone)
    )
  }

  _updateMomoImages(momoPhone) {
    if (this.MTN_BJ_MSISDN.test(momoPhone)) this.selectedProvider = "MTN_BJ"
    else if (this.MOOV_BJ_MSISDN.test(momoPhone)) this.selectedProvider = "MOOV_BJ"
    else if (this.MOOV_NE_MSISDN.test(momoPhone)) this.selectedProvider = "MOOV_NE"
    else if (this.AIRTEL_NE_MSISDN.test(momoPhone)) this.selectedProvider = "AIRTEL_NE"
    else if (this.ZAMANI_NE_MSISDN.test(momoPhone)) this.selectedProvider = "ZAMANI_NE"

    if (this.selectedProvider) {
      this.momoImagesTargets.forEach(img => {
        if (img.dataset.provider === this.selectedProvider) {
          img.classList.remove("blanc")
          img.classList.add("selected")
        }
      })
    }
  }

  _requestMomoPayment() {
    hideElement(this.momoPhoneErrorMessageTarget)

    if (this.momoPhone == '') {
      showElement(this.momoPhoneErrorMessageTarget)
      return;
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Ipay-Target-Environment", "live");
    myHeaders.append("Ipay-Payment-Type", "mobile");
    myHeaders.append("Authorization", `Bearer ${this.publicKeyValue}`);

    var phoneCountry = this.momoPhoneCountryTarget.value
    var msisdnCode = this.momoPhoneCountryTarget.value == 'NE' ? '227' : '229'

    var raw = JSON.stringify({
      "amount": this.amount,
      "country": phoneCountry,
      "currency": "XOF",
      "transaction_id": this.transactionIdValue || Date.now(),
      "msisdn": msisdnCode + this.momoPhone.replace(/\s+/g, ''),
      "customer_name": this.name,
      "item_id": this.objectIdValue,
      "item_type": this.objectClassValue,
      "payment_option": 'mobile',
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    this._toggleButtonLoading();

    fetch("/api/sdk/v1/payments", requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(response => {
            this._toggleButtonLoading();
            this.paymentReference = response.reference;
            if (this.selectedProvider === 'ZAMANI_NE') {
              this.momoPendingMessageTarget.innerHTML = 'Taper <b>#146#</b> pour valider la transaction.';
            }
            this._displayStep("momoPendingStep");
          });
        } else {
          response.json().then(response => {
            this._toggleButtonLoading();
            showElement(this.paymentNotificationTarget)
            this.paymentNotificationContentTarget.textContent = this._responseErrorMessage(response.message);
          })
        }
      })
      .catch(error => {
        console.log(error, 'error')
      });
  }

  _requestAmanataPayment() {
    hideElement(this.amanataPhoneErrorMessageTarget)

    if (this.amanataPhone == '') {
      showElement(this.amanataPhoneErrorMessageTarget)
      return
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Ipay-Target-Environment", "live");
    myHeaders.append("Ipay-Payment-Type", "amanata");
    myHeaders.append("Authorization", `Bearer ${this.publicKeyValue}`);

    var phoneCountry = this.amanataPhoneCountryTarget.value
    var msisdnCode = phoneCountry == 'NE' ? '227' : '229'

    var raw = JSON.stringify({
      "amount": this.amount,
      "country": phoneCountry,
      "currency": "XOF",
      "transaction_id": this.transactionIdValue || Date.now(),
      "msisdn": msisdnCode + this.amanataPhone,
      "customer_name": this.name,
      "item_id": this.objectIdValue,
      "item_type": this.objectClassValue,
      "payment_option": 'amanata',
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    this._toggleButtonLoading();

    fetch("/api/sdk/v1/payments", requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(response => {
            this._toggleButtonLoading();
            this.paymentReference = response.reference;
            this._displayStep("amanataPendingStep");
          });
        } else {
          response.json().then(response => {
            this._toggleButtonLoading();
            showElement(this.paymentNotificationTarget)
            this.paymentNotificationContentTarget.textContent = this._responseErrorMessage(response.message);
          })
        }
      })
      .catch(error => {
        console.log(error, 'error')
      });
  }

  _requestBankCardPayment() {
    hideElement(this.cardPhoneErrorMessageTarget)
    hideElement(this.panErrorMessageTarget)
    hideElement(this.expErrorMessageTarget)
    hideElement(this.cvvErrorMessageTarget)

    if (this.cardPhone == '') {
      showElement(this.cardPhoneErrorMessageTarget)
      return
    }
    if (this.pan == '' || !this.isValidatedPan) {
      this._validePan();
      return
    }
    if (this.exp == '' || !this.isValidatedExp) {
      this._valideExp();
      return
    }
    if (this.cvv == '' || !this.isValidatedCvv) {
      this._valideCvv();
      return
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Ipay-Target-Environment", "live");
    myHeaders.append("Ipay-Payment-Type", "card");
    myHeaders.append("Authorization", `Bearer ${this.publicKeyValue}`);

    var raw = JSON.stringify({
      "amount": this.amount,
      "country": this.cardCountry,
      "currency": "XOF",
      "transaction_id": this.transactionIdValue || Date.now(),
      "customer_name": this.name,
      "msisdn": this.countryCode + this.cardPhoneTarget.value.replace(/\s+/g, ''),
      "item_id": this.objectIdValue,
      "item_type": this.objectClassValue,
      "payment_option": 'card',
      "pan": this._unmask(this.pan),
      "exp": this.exp,
      "cvv": this.cvv,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    this._toggleButtonLoading();
    fetch("/api/sdk/v1/payments/bank_card_payment", requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(response => {
            if (response.state == "AWAIT_3DS") {
              this._sendBrowserInfo(response)
            }
            this.paymentReference = response.reference;
          });
        } else {
          response.json().then(response => {
            this._toggleButtonLoading();
            showElement(this.paymentNotificationTarget)
            this.paymentNotificationContentTarget.textContent = this._responseErrorMessage(response.message);
          })
        }
      })
      .catch(error => {
        console.log(error, 'error')
      });
  }

  _sendBrowserInfo(response) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Ipay-Target-Environment", "live");
    myHeaders.append("Ipay-Payment-Type", "card");
    myHeaders.append("Authorization", `Bearer ${this.publicKeyValue}`);

    var raw = JSON.stringify({
      reference: response.reference,
      order_reference: response.order_reference,
      payment_reference: response.payment_reference
    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    fetch("/api/sdk/v1/payments/send_device_information", requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(response => {
            this._toggleButtonLoading();
            if (response.state == "AWAIT_3DS") {
              this.data3dsUrlTarget.src = `${response.term_url_get}?acs_url=${response.acs_url}&base64_encoded_cqeq=${response.base64_encoded_cqeq}&challenge_notification_url=${response.notification_url}`
              this._displayStep("card3dsStep");
            }
          });
        } else {
          response.json().then(response => {
            this._toggleButtonLoading();
            showElement(this.paymentNotificationTarget)
            this.paymentNotificationContentTarget.textContent = this._responseErrorMessage(response.message);
          })
        }
      })
      .catch(error => {
        console.log(error, 'error')
      });
  }

  _requestStaPayment(type) {
    hideElement(this[`${type}PhoneErrorMessageTarget`])

    if (this[`${type}Phone`] == '') {
      showElement(this[`${type}PhoneErrorMessageTarget`])
      return
    }

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Ipay-Target-Environment", "live");
    myHeaders.append("Ipay-Payment-Type", "sta");
    myHeaders.append("Authorization", `Bearer ${this.publicKeyValue}`);

    var phoneCountry = this[`${type}PhoneCountryTarget`].value
    var msisdnCode = this[`${type}PhoneCountryTarget`].value == 'NE' ? '227' : '229'

    var raw = JSON.stringify({
      "amount": this.amount,
      "country": phoneCountry,
      "currency": "XOF",
      "transaction_id": this.transactionIdValue || Date.now(),
      "msisdn": msisdnCode + this[`${type}Phone`].replace(/\s+/g, ''),
      "customer_name": this.name,
      "item_id": this.objectIdValue,
      "item_type": this.objectClassValue,
      "payment_option": 'sta',
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    this._toggleButtonLoading();

    fetch("/api/sdk/v1/payments", requestOptions)
      .then(response => {
        if (response.ok) {
          response.json().then(response => {
            this._toggleButtonLoading();
            this.paymentReference = response.reference;
            this[`${type}PublicReferenceTarget`].textContent = response.public_reference;
            this._displayStep(`${type}PendingStep`);
          });
        } else {
          response.json().then(response => {
            this._toggleButtonLoading();
            showElement(this.paymentNotificationTarget)
            this.paymentNotificationContentTarget.textContent = this._responseErrorMessage(response.message);
          })
        }
      })
      .catch(error => {
        console.log(error, 'error')
      });
  }

  _displayStep(step) {
    this.stepTargets.forEach(elt => elt.classList.add('is-hidden'));
    this[`${step}Target`].classList.remove('is-hidden');
  }

  _toggleButtonLoading() {
    this.buttonTarget.classList.toggle('is-loading')
  }

  _mask(value, limit, separator) {
    var output = [];
    for (let i = 0; i < value.length; i++) {
      if ( i !== 0 && i % limit === 0) {
        output.push(separator);
      }

      output.push(value[i]);
    }

    return output.join("");
  }

  _unmask(value) {
    return value.replace(/[^\d]/g, "")
  }

  _checkSeparator(position, interval) {
    return Math.floor(position / (interval + 1))
  }

  _validePan() {
    if (this.pan == '') {
      this.panErrorMessageTarget.textContent = 'Le pan doivent être remplis'
      showElement(this.panErrorMessageTarget)
      return
    }

    if (!this.isValidatedPan) {
      this.panErrorMessageTarget.textContent = 'Format incorrect'
      showElement(this.panErrorMessageTarget)
      return
    }

    hideElement(this.panErrorMessageTarget)
  }

  _valideExp() {
    if (this.exp == '') {
      this.expErrorMessageTarget.textContent = 'La date d\'expiration doit être rempli'
      showElement(this.expErrorMessageTarget)
      return
    }

    if (!this.isValidatedExp) {
      this.expErrorMessageTarget.textContent = 'Format incorrect'
      showElement(this.expErrorMessageTarget)
      return
    }

    if (this.exp.slice(0, 2) > 12) {
      this.expErrorMessageTarget.textContent = 'Invalid mois'
      showElement(this.expErrorMessageTarget)
      return
    }

    if (this.exp.slice(3, 5) < new Date().getFullYear().toString().slice(2, 4)) {
      this.expErrorMessageTarget.textContent = 'Carte expirer'
      showElement(this.expErrorMessageTarget)
      return
    }

    hideElement(this.expErrorMessageTarget)
  }

  _valideCvv() {
    if (this.cvv == '') {
      this.cvvErrorMessageTarget.textContent = 'Le cvv doit être rempli'
      showElement(this.cvvErrorMessageTarget)
      return
    }

    if (!this.isValidatedExp) {
      this.cvvErrorMessageTarget.textContent = 'Format incorrect'
      showElement(this.cvvErrorMessageTarget)
      return
    }

    hideElement(this.cvvErrorMessageTarget)
  }

  _responseErrorMessage(message) {
    var error;
    switch (message) {
      case 'Bad Request: Missing Body Params':
        error = 'Paramètres manquants';
        break;
      case 'Bad Request: Country Not Allowed':
        error = 'Pays non autorisé';
        break;
      case 'Bad Request: Currency Not Allowed':
        error = 'Devise non autorisée';
        break;
      case 'Bad Request: Amount Not Valid':
        error = 'Montant non valide';
        break;
      case 'Bad Request: Incorrect MSISDN':
        error = 'Numéro incorrect';
        break;
      default:
        error = 'Une erreur est survenue, veuillez réessayer';
    }
    return error;
  }
}
